<template>
  <v-app>
    <!-- Загрузка -->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="blue-grey" dark>
        <v-card-text>
          Загрузка...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Кнопка вверх -->
    <v-btn
      v-show="btnUpShow && btnReloadShow == false"
      class="app-button"
      fab
      dark
      small
      rounded
      color="darkgrey"
      @click="MoveTo('top')"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>

    <v-btn
      v-show="btnReloadShow"
      color="#1976d2"
      class="ma-2 white--text app-reload"
      small
      @click="reloadPage()"
    >
      <v-icon left dark> mdi-reload </v-icon> Обновить
    </v-btn>

    <!-- Левая панель -->
    <v-navigation-drawer style="z-index: 99" v-model="drawer" app>
      <v-list-item style="height: 58.8px">
        <v-list-item-content>
          <v-list-item-title class="text-center text-h6">
            {{ AppName }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-center">
            Электронное расписание
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-btn v-if="mobile" icon outlined @click.stop="drawer = !drawer"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <div class="mt-4 mx-auto" max-width="300">
        <v-card-text class="pt-0">
          <div
            style="margin-top: -8px"
            class="text-subtitle-1 font-weight-light mb-2 black--text"
          >
            История болезни № {{ username }}
          </div>

          <div
            class="text-center subheading font-weight-light black--text"
          ></div>
          <v-divider class="my-2"></v-divider>
          <div v-if="(active_page == 'Main') & (pagination.length > 0)">
            <v-icon class="mr-2" small> mdi-clock </v-icon>
            <span class="text-caption black--text font-weight-light"
              >{{ dateStart }} - {{ dateEnd }}</span
            >
            <br />
            <span class="text-caption black--text font-weight-light"
              >Лечащий врач: <b style="font-weight: bold">{{ doctor }}</b></span
            >
            <br />
            <span class="text-caption black--text font-weight-light"
              >Назначена диета:
              <b style="font-weight: bold">{{ dieta }}</b></span
            >
            <br />
            <v-divider class="my-2"></v-divider>
            <div
              v-if="(active_page == 'Main') & (pagination.length > 0)"
              class="text-subtitle-1 font-weight-light mb-2 black--text text-center"
            >
              Даты лечения
            </div>
          </div>
          <v-chip-group v-if="active_page == 'Main'" column>
            <div v-for="item in this.pagination" :key="item.id">
              <v-btn
                class="wdt toAnchor"
                small
                outlined
                @click="MoveTo(item.id)"
              >
                {{ item.name }}
              </v-btn>
            </div>
          </v-chip-group>
        </v-card-text>
      </div>

      <template v-slot:append>
        <v-card>
          <v-card-text>
            <v-timeline dense>
              <v-timeline-item color="green lighten-3" icon="mdi-check" small>
                <v-row>
                  <v-col cols="12">
                    <strong>Процедура пройдена</strong>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item color="warning" small>
                <v-row>
                  <v-col cols="12">
                    <strong>Процедура НЕ пройдена</strong>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
          <v-divider class="my-2"></v-divider>
          <div class="pa-2">
            <v-subheader
              >Данные актуальны<br />на: {{ actualdate }}</v-subheader
            >
          </div>
        </v-card>
      </template>
    </v-navigation-drawer>

    <!-- Основная страница -->
    <v-app-bar app style="height: 66.8px">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <!--  {{ кнопки навигации справа-сверху }} -->

      <!-- 
      <v-btn
        :color="active_page == 'Help' ? '#607D8B' : '607D8B'"
        icon
        @click="installapp"
      >
        <v-icon>mdi-cellphone-arrow-down</v-icon>
      </v-btn> 
      <v-btn
        :color="active_page == 'Help' ? '#607D8B' : 'Grey'"
        icon
        @click="today = '2024-06-12'"
      >
        <v-icon>mdi-chat-question-outline</v-icon>
      </v-btn>

      <v-btn
        :color="active_page == 'Main' ? '#607D8B' : 'Grey'"
        icon
        @click="active_page = 'Main'"
      >
        <v-icon>mdi-home</v-icon>
      </v-btn>-->

      <!--<v-btn
        :color="active_page == 'News' ? '#607D8B' : 'Grey'"
        icon
        @click="active_page = 'News'"
      >
        <v-icon>mdi-book-open-variant</v-icon>
      </v-btn>
      -->
      <!--<v-btn @click="catchData()">Click</v-btn>-->
    </v-app-bar>

    <v-main id="top">
      <!-- active_page Main -->
      <v-container v-if="active_page == 'Main'" fluid>
        <!-- ПРИ НАЛИЧИИ ОШИБКИ -->
        <v-row
          style="margin-bottom: 0em; margin-top: 1em"
          v-if="errortext != ''"
        >
          <v-col>
            <div>
              <v-card>
                <v-card style="margin-bottom: 0em" dark flat class="sticky">
                  <v-img
                    style="z-index: 10; margin-top: -1px"
                    height="200px"
                    src="../src/istok_main.jpg"
                    gradient="to top, rgba(0,0,0,.64), rgba(0,0,0,.64)"
                  >
                    <v-container class="fill-height">
                      <v-row align="center">
                        <v-row justify="center">
                          <div class="text-h4 font-weight-light">
                            {{ errorname }}
                          </div>
                        </v-row>
                      </v-row>
                    </v-container>
                  </v-img>
                </v-card>

                <v-card-text v-if="waitforqr == false" class="black--text">
                  <br />
                  {{ errortext }}
                </v-card-text>

                <v-card-text v-if="waitforqr" class="black--text">
                  <br />
                  {{ errortext }} <br /><br />

                  <v-btn @click="dialog = true"> Сканировать QR </v-btn>

                  <v-dialog v-model="dialog" width="auto">
                    <v-card
                      max-width="400"
                      prepend-icon="mdi-update"
                      text="Your application will relaunch automatically after the update is complete."
                      title="Update in progress"
                      ><QR @newData="updateNewData"/>

                      <v-card-actions v-if="newID !== null" class="black--text">
                        Обнаружен ID: {{ newID }} <v-spacer></v-spacer>
                        <v-btn @click="applyNewData"> Применить </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <!-- Прочие назначения -->
        <v-row
          style="padding-top: 1em; margin-bottom: -3em"
          v-if="anotherList.length > 0"
        >
          <v-col>
            <v-card>
              <v-card-title class="blue-grey white--text">
                <span class="text-h6">Назначения</span>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-timeline dense>
                <v-timeline-item v-for="item in anotherList" :key="item.name">
                  <strong>{{ item.place }}</strong>
                  <small v-if="item.time != ''"> - </small> {{ item.time }}
                  <div class="text-caption">
                    {{ item.name }} ({{ item.number }})
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-card>
          </v-col>
        </v-row>

        <!-- Расписание пациента по дням -->
        <v-row>
          <v-col>
            <div v-for="item in proceduresList" :key="item.day">
              <div>
                <br :id="item.day" />
                <p></p>
              </div>

              <v-card>
                <v-card
                  style="margin-bottom: 0em"
                  dark
                  flat
                  class="sticky blue-grey white--text"
                >
                  <v-img
                    style="z-index: 10; margin-top: -1px"
                    height="100px"
                    src="../src/istok_main.jpg"
                    gradient="to top, rgba(0,0,0,.64), rgba(0,0,0,.64)"
                  >
                    <v-container class="fill-height">
                      <v-row align="center">
                        <v-row justify="center">
                          <div class="text-h4 font-weight-light">
                            {{ item.dayformate }},
                          </div>
                        </v-row>
                      </v-row>

                      <v-row align="center">
                        <v-row justify="center">
                          <div class="text-h4 font-weight-light">
                            {{ item.dayname }}
                          </div>
                        </v-row>
                      </v-row>
                    </v-container>
                  </v-img>
                </v-card>

                <v-card-text
                  v-for="(list, value) in item.list"
                  :key="list.number"
                  class="py-0"
                  :style="
                    value % 2
                      ? 'background-color: rgb(0 0 0 / 8%);'
                      : 'background-color:white;'
                  "
                >
                  <v-timeline align-top dense>
                    <v-timeline-item
                      :color="list.complete ? 'green lighten-3' : 'warning'"
                      :icon="list.complete ? 'mdi-check' : ''"
                      small
                    >
                      <v-row class="pt-1">
                        <v-col cols="4">
                          <strong>{{ list.time }}</strong>
                        </v-col>
                        <v-col cols="8">
                          <strong>Кабинет: {{ list.cabinet }}</strong>
                          <div>
                            {{ list.name }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- ^active_page Main^ -->

      <!-- active_page News -->
      <v-container v-if="active_page == 'News'" fluid>
        <v-row style="margin-bottom: 0em; margin-top: 1em">
          <v-col>
            <div>
              <v-card class="elevation-16 mx-auto">
                <v-card-title class="text-h5"> Новость из 1С </v-card-title>
                <v-card-text>
                  Список новостей ведется в программе 1С, раздел "Временное
                  название раздела".
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                  <v-btn text> 29 Февраля 2024 </v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-row style="margin-bottom: 0em; margin-top: 1em">
          <v-col>
            <div>
              <v-card class="elevation-16 mx-auto">
                <v-card-title class="text-h5"> Новость номер 2 </v-card-title>
                <v-card-text>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Itaque cum officiis magni id repellat enim provident nemo,
                  placeat dicta maiores mollitia cupiditate, blanditiis aliquid,
                  impedit recusandae possimus beatae dolore assumenda?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                  <v-btn text> 6 Января 2024 </v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-row style="margin-bottom: 0em; margin-top: 1em">
          <v-col>
            <div>
              <v-card class="elevation-16 mx-auto">
                <v-card-title class="text-h5">
                  Новый аппарат фотодинамической терапии HELEO PRO LED™
                </v-card-title>
                <v-card-text>
                  Встречайте HELEO PRO LED™ — инновационный аппарат
                  фотодинамической терапии для оздоровления кожи и эффективной
                  омолаживающей терапии без инъекций.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                  <v-btn text> 24 декабря 2023 </v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- ^active_page News^ -->

      <!-- active_page HELP -->
      <v-container v-if="active_page == 'Help'" fluid>
        <v-row style="margin-bottom: 0em; margin-top: 1em">
          <v-col>
            <div>
              <v-card class="elevation-16 mx-auto">
                <v-card-title class="text-h5"> Не нашли ответ? </v-card-title>
                <v-card-text>
                  Свяжитесь с нами по телефону <br />
                  <strong>8 (800) ХХХ - ХХ - ХХ</strong>
                  <br />
                  или E-mail: <strong>test@adress.rus</strong> <br /><br />
                  Наш адрес: <strong>г. Ессентуки, улица, ХХ</strong>
                </v-card-text>
              </v-card>
            </div>
            <br />

            <div>
              <v-card-title class="blue-grey white--text">
                <span class="text-h6">Назначения</span>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card>
                <v-card-text>
                  <strong>Раздел "Назначения"</strong>
                  <br />
                  Выводится при наличии таких назначений врача как Бассейн,
                  Лечебная физкультура и т.д., не привязаны к конкретным датам,
                  можно посещать в соответствии с графиком работы.
                </v-card-text>
                <v-timeline dense>
                  <v-timeline-item>
                    <strong>Адрес (кабинет)</strong>
                    <small> - </small> Время назначения (при наличии)
                    <div class="text-caption">
                      Наименование назначения (Количество процедур)
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </v-card>
            </div>
            <br />

            <div>
              <v-card>
                <v-card style="margin-bottom: 0em" dark flat class="sticky">
                  <v-img
                    style="z-index: 10; margin-top: -1px"
                    height="100px"
                    src="../src/istok_main.jpg"
                    gradient="to top, rgba(0,0,0,.64), rgba(0,0,0,.64)"
                  >
                    <v-container class="fill-height">
                      <v-row align="center">
                        <v-row justify="center">
                          <div class="text-h4 font-weight-light">
                            Расписание
                          </div>
                        </v-row>
                      </v-row>
                      <v-row align="center">
                        <v-row justify="center">
                          <div class="text-h4 font-weight-light">
                            Дата, день недели
                          </div>
                        </v-row>
                      </v-row>
                    </v-container>
                  </v-img>
                </v-card>

                <v-card-text>
                  <strong>Раздел "Расписание"</strong>
                  <br />
                  Содержит данные о времени, адресе и наименовании назначенной
                  процедуры, цвет кружка означает была ли процедура пройдена
                  (зеленый цвет) или ее еще предстоит пройти (оранжевый).
                </v-card-text>

                <v-card-text>
                  <v-timeline align-top dense>
                    <v-timeline-item :color="'green lighten-3'" small>
                      <v-row class="pt-1">
                        <v-col cols="4">
                          <strong>Процедура пройдена</strong>
                        </v-col>
                        <v-col cols="8">
                          <strong>Кабинет (Адрес)</strong>
                          <div>Наименование процедуры</div>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
                <v-card-text>
                  <v-timeline align-top dense>
                    <v-timeline-item :color="'warning'" small>
                      <v-row class="pt-1">
                        <v-col cols="4">
                          <strong>Процедура НЕ пройдена</strong>
                        </v-col>
                        <v-col cols="8">
                          <strong>Кабинет: №</strong>
                          <div>Наименование процедуры</div>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- ^active_page HELP^ -->
    </v-main>
  </v-app>
</template>

<script>
import QR from "./components/QR";

export default {
  name: "App",
  components: { QR },

  data: () => ({
    AppName: "",
    mode: "inet", //lan, inet
    apiMethod: "",
    loading: true,
    showmessage: false,
    drawer: false,
    mobile: false,
    btnUpShow: false,
    btnReloadShow: false,
    scrollTopNum: "",
    deferredPrompt: null,
    waitforqr: false,
    dialog: false,

    link_inet: "https://ooo-plus.ru/getinfo.php",
    link_lan: "http://192.168.100.102/getinfo.php",
    //link_api: "http://192.168.100.102/plus-api_v2/document/getinfo.php",

    errorname: "",
    errortext: "",
    message: "",
    messagetype: "red darken-2",

    active_page: "Main",

    id: "",
    pin: "",
    newID: null,
    newPIN: null,
    massiv: [],
    username: "-",
    dateStart: "",
    dateEnd: "",
    dieta: "",
    doctor: "",
    actualdate: "Подождите, идет загрузка...",
    proceduresList: [],
    anotherList: [],
    pagination: [],
    today: null,
    todaymove: null,

    monthNames: [
      "Января",
      "Февраля",
      "Марта",
      "Апреля",
      "Мая",
      "Июня",
      "Июля",
      "Августа",
      "Сентября",
      "Октября",
      "Ноября",
      "Декабря",
    ],
    dayNames: [
      "Воскресенье",
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
    ],
    dayNamesShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  }),

  created() {
    let urlParams = new URLSearchParams(window.location.search);
    this.id = urlParams.get("id");
    this.pin = urlParams.get("pin");

    if (this.id == null) {
      //console.log("Ищу данные в кеше");

      const MyAppName = localStorage.getItem("MyAppName") || null;
      const MyDocID = localStorage.getItem("MyDocID") || null;
      const MyPinCode = localStorage.getItem("MyPinCode") || null;

      if (MyDocID !== "") {
        this.id = MyDocID;
        this.pin = MyPinCode;
        this.AppName = MyAppName;
      }
    }

    if ((this.id != null) & (this.pin != null)) {
      //console.log("Грузим");
      this.loading = true;

      this.load_inet();
    } else {
      //Проверим данные в кеше, подставим если имеются!
      if (this.id == 1) {
        //ok
        console.log("ok");
      } else {
        //console.log("Не грузим");
        this.massiv = [];
        this.username = "";
        this.dateStart = "";
        this.dateEnd = "";
        this.actualdate = "";
        this.username = " -";
        this.actualdate = " -";
        this.loading = false;
        this.waitforqr = true;

        //Подскажем юзеру, что ссылка неполная
        this.errorname = "Санаторий ИСТОКЪ";
        this.errortext =
          "Недостаточно параметров для продолжения работы, отсканируйте первичный QR-код или обратитесь к администратору";
      }
    }

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
  },

  methods: {
    UserException: function (message) {
      this.message = message;
      this.name = "Искусственное исключение";
    },

    UpdateCache() {
      //console.log("Update cache");
      if (this.id != "") {
        localStorage.setItem("MyDocID", this.id);
        localStorage.setItem("MyPinCode", this.pin);
        localStorage.setItem("MyAppName", this.AppName);
      }
    },

    updateNewData(data){
      //console.log("new data", data);
      this.newID = data.NewID;
      this.newPIN = data.NewPIN;
    },

    applyNewData(){
      this.id = this.newID;
      this.pin = this.newPIN;
      this.dialog = false;
      this.loading = true;
      this.load_inet();
    },

    async installapp() {
      console.log("Try install");
      this.deferredPrompt.prompt();
    },

    async load_inet() {
      //Выводим дату запроса (если отдыхюащий утром запросил данные а к вечеру они устарели то нужно дать ему понять об этом, чтобы небыло претензий)
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      var Hour = today.getHours();
      var Min = today.getMinutes();
      this.actualdate =
        dd +
        "." +
        mm +
        "." +
        yyyy +
        " " +
        ("0" + Hour).slice(-2) +
        ":" +
        ("0" + Min).slice(-2);

      if (this.mode == "inet") {
        this.apiMethod = this.link_inet;
      } else if (this.mode == "lan") {
        this.apiMethod = this.link_lan;
      }

      this.axios
        .post(this.apiMethod, {
          action: "catchData",
          DocID: this.id,
          PinCode: this.pin,
        })
        .then((response) => {
          //console.log(response);
          if (response.status == 200) {
            //Название приложения из GetInfo
            this.AppName =
              response.data.About + " ''" + response.data.Name + "''";
            if (typeof response.data === "string") {
              //console.log("Какая то ошибка...");
              this.existdaygone = [];
              this.proceduresList = [];
              this.pagination = [];
              this.loading = false;
              this.AppName = "";
              this.errorname = "Ошибка";
              this.errortext =
                "На сервере проводятся технические работы, попробуйте позднее";
            } else if (response.data.DocInfo.length != 0) {
              // Основные данные
              this.massiv = response.data;
              this.username = this.id;
              this.dateStart =
                new Date(this.massiv.DocInfo.DateStart).getDate() +
                " " +
                this.monthNames[
                  new Date(this.massiv.DocInfo.DateStart).getMonth()
                ];
              this.dateEnd =
                new Date(this.massiv.DocInfo.DateEnd).getDate() +
                " " +
                this.monthNames[
                  new Date(this.massiv.DocInfo.DateEnd).getMonth()
                ] +
                ", " +
                new Date(this.massiv.DocInfo.DateEnd).getFullYear();

              if (response.data.DocInfo.Diet == "") {
                this.dieta = " - ";
              } else {
                this.dieta = response.data.DocInfo.Diet;
              }

              if (response.data.DocInfo.Doctor == "") {
                this.doctor = " - ";
              } else {
                this.doctor = response.data.DocInfo.Doctor;
              }

              if (this.massiv.DocInfo.length == 0) {
                this.username = "Информация не найдена!";
                this.actualdate = "Обратитесь в регистратуру";
              } else {
                // Прочие процедуры
                let an = [];
                if (this.massiv.AddSH.length != 0) {
                  this.massiv.AddSH.forEach(function (item) {
                    let tmpobj = {};
                    tmpobj["name"] = item.ProcedureName;
                    tmpobj["time"] = item.Period;
                    tmpobj["place"] = item.Adress;
                    tmpobj["number"] = item.Count;
                    an.push(tmpobj);
                  });
                }
                this.anotherList = an;

                // Основные процедуры
                let tmpDays = [];
                let pagDays = []; //для кнопок "Список лечебных дней"
                let myexistdaygone = false;

                let GroupDays = this.massiv.MainSH.reduce((x, y) => {
                  (x[y.DateNazn] = x[y.DateNazn] || []).push(y);
                  return x;
                }, {});

                for (var key in GroupDays) {
                  //console.log(key);

                  // Каждый день выведем в виде кнопок бокового меню, для быстрого перехода к дням
                  let paginationdays = {};
                  paginationdays["id"] = key;
                  paginationdays["link"] = "#" + String(key);
                  let myday = String(new Date(key).getDate());
                  let mymonth = ("0" + (new Date(key).getMonth() + 1)).slice(
                    -2
                  );
                  paginationdays["name"] = myday + "." + mymonth;
                  pagDays.push(paginationdays);

                  // Список процедур на день
                  let proc_list = [];
                  GroupDays[key].forEach(function (item) {
                    let vremya = String(new Date(item.Time).getHours());
                    let stime = null;

                    //console.log(item);

                    if (vremya == "0") {
                      stime = " "; //+("0" + String(new Date(item.Time).getHours())).slice(-2) + ":" + ("0" + String(new Date(item.Time).getMinutes())).slice(-2);
                      //console.log(String(new Date(item.Time)));
                    } else {
                      stime =
                        ("0" + String(new Date(item.Time).getHours())).slice(
                          -2
                        ) +
                        ":" +
                        ("0" + String(new Date(item.Time).getMinutes())).slice(
                          -2
                        );
                    }

                    let tmpproc = {};
                    tmpproc["name"] = item.ProcedureName;
                    tmpproc["time"] = stime;

                    tmpproc["cabinet"] = item.Cabinet;
                    tmpproc["post"] = item.Post;
                    if (item.Complete == "1") {
                      tmpproc["complete"] = true;
                    } else {
                      tmpproc["complete"] = false;
                    }
                    proc_list.push(tmpproc);
                  });

                  //Сортировка по времени
                  //console.log(proc_list);
                  proc_list.sort((a, b) => (a["time"] > b["time"] ? 1 : -1));
                  //console.log(proc_list);

                  // Подготовленный список процедур на день сохраним в массив дней
                  let dayObj = {};
                  dayObj["day"] = key;
                  dayObj["dayformate"] =
                    new Date(key).getDate() +
                    " " +
                    this.monthNames[new Date(key).getMonth()]; //item.DayFormate;
                  dayObj["dayname"] = this.dayNames[new Date(key).getDay()];
                  dayObj["list"] = proc_list;
                  tmpDays.push(dayObj);

                  //console.log(key); //id="2024-06-06"
                  let WhatADay = new Date().toISOString().slice(0, 10);
                  //console.log(WhatADay);
                  if (WhatADay == key) {
                    this.today = key;
                    //console.log("HOLA, i found the `today`: " + this.today);
                  }
                }

                // Закончили с формированием, заносим данные в константы и выключаем загрузку.
                this.existdaygone = myexistdaygone;
                this.proceduresList = tmpDays;
                this.pagination = pagDays;
                this.loading = false;
                this.errorname = "";
                this.errortext = "";
              }
            } else {
              this.existdaygone = [];
              this.proceduresList = [];
              this.pagination = [];
              this.loading = false;
              this.errorname = "Нет назначений";
              this.errortext =
                "Нет данных для отображения! Попробуйте позднее или обратитесь к администратору";
            }
          } else {
            //response.status не 200
            this.existdaygone = [];
            this.proceduresList = [];
            this.pagination = [];
            this.loading = false;
            this.errorname = "Сервис временно недоступен";
            this.errortext =
              "На сайте ведутся технические работы, попробуйте позднее.";
            //console.log(response.data);
          }
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.username = "";
          this.errorname = "Ошибка";
          this.errortext = err;
          this.actualdate =
            "Данные не загружены, попробуйте позднее или обратитесь к администратору";
        }); //load_inet()\\

      //После выполнения обновим данные в кеше
      this.UpdateCache();
    },

    disableform: function () {
      this.loading = true;
    },

    switchdaygone() {
      if (this.showdaygone == true) {
        this.showdaygone = false;
        this.infodaygone = "Показать прошедшие дни";
      } else {
        this.showdaygone = true;
        this.infodaygone = "Скрыть прошедшие дни";
      }
    },

    MoveTo: function (goLink) {
      //Переходим к элементу по ID (Навигация) - сделано так чтобы не изменялась ссылка на #выбранныйИД
      document.getElementById(goLink).scrollIntoView();
    },

    testbtn: function () {
      console.log("Это тест");
      console.log(this);
    },

    reloadPage() {
      window.location.reload();
    },

    scrollTo() {
      let top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      this.scrollTopNum = top;
    },
  },

  mounted() {
    //console.log(new Date());
    const portrait = window.matchMedia("(orientation: portrait)").matches;

    if (
      /Android|kiwi|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.mobile = true;
    }

    if (portrait == false) {
      //console.log('Device orientation is Portrait');
      this.drawer = true;
    }

    window.addEventListener("scroll", this.scrollTo, true);

    setTimeout(() => {
      let mv = document.getElementById(this.today);
      //console.log("ok");
      if (mv != null) {
        mv.scrollIntoView();
        //console.log("ok");
      }
    }, 500);

    //console.log(new Date());
    setTimeout(() => {
      this.btnReloadShow = true;
      //console.log("time to reload");
      //console.log(new Date());
    }, 600000); //600000 - 10 минут
  },

  //onBeforeMount() {
  //window.removeEventListener("scroll", userScroll);
  //},

  watch: {
    scrollTopNum: function () {
      if (this.scrollTopNum > 50) {
        this.btnUpShow = true;
      } else {
        this.btnUpShow = false;
      }
    },
  },
};
</script>

<style scoped>
.sticky {
  z-index: 3;
  position: sticky;
  min-height: 3em;
  top: 3.5em;
  transform: translate(0, 0em);
  background: lightpink;
}

.wdt {
  min-width: 64px !important;
  max-width: 64px !important;
  margin: 0px 2px 1px 1px;
}

.app-button {
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 98;
}

.app-reload {
  border-radius: 0%;
  padding: 40px;
  cursor: pointer;
  position: fixed;
  bottom: 14px;
  right: 20px;
  z-index: 99;
}

.showButton {
  display: block;
  display: none;
}
</style>
